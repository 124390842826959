"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.licenceCountCssClass = exports.notifyError = exports.notifySuccess = exports.handleApiCallWithoutNotify = exports.handleApiCall = exports.botVersionTimeFormat = void 0;
const toast_state_1 = require("@/state/toast.state");
__exportStar(require("@/../../common-ui/src/dashboard/helpers"), exports);
exports.botVersionTimeFormat = 'YYYY-MM-DDTHH:mm';
// empty noop methods as default params
async function handleApiCall({ apiFunction, successMessage = 'Erfolg!', errorMessage = 'Fehler!', successMessageCallback = null, successCallback = (response) => { }, errorCallback = (error) => { }, }) {
    var _a, _b, _c;
    try {
        let response = await apiFunction();
        if (successMessage) {
            notifySuccess(successMessage);
        }
        if (successMessageCallback) {
            notifySuccess(successMessageCallback(response));
        }
        successCallback(response);
    }
    catch (err) {
        console.error(err, (_a = err.response) === null || _a === void 0 ? void 0 : _a.data);
        if ((_c = (_b = err.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) {
            notifyError(`${errorMessage}: ${err.response.data.error}, ${err.response.data.message}`);
        }
        else {
            notifyError(errorMessage);
        }
        errorCallback(err);
    }
}
exports.handleApiCall = handleApiCall;
async function handleApiCallWithoutNotify({ apiFunction, successCallback = () => { }, errorCallback = () => { }, }) {
    try {
        let response = await apiFunction();
        successCallback(response);
    }
    catch (e) {
        errorCallback(e);
    }
}
exports.handleApiCallWithoutNotify = handleApiCallWithoutNotify;
function notifySuccess(successMessage = 'Erfolg!') {
    toast_state_1.toastStore.setToast(successMessage);
}
exports.notifySuccess = notifySuccess;
function notifyError(errorMessage = 'Fehler!') {
    toast_state_1.toastStore.setToast(errorMessage);
}
exports.notifyError = notifyError;
function licenceCountCssClass(meta, failClass, okClass) {
    var _a;
    if (meta.anzahlBehandler.za && meta.anzahlBehandler.pzr && ((_a = meta.stripe) === null || _a === void 0 ? void 0 : _a.metricsBehandlerLizenzen)) {
        if (meta.anzahlBehandler.za + meta.anzahlBehandler.pzr * 0.5 > meta.stripe.metricsBehandlerLizenzen) {
            return failClass;
        }
        else {
            return okClass;
        }
    }
    return '';
}
exports.licenceCountCssClass = licenceCountCssClass;
