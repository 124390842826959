"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadEweStats = exports.loadR4cFactoringBackendStatus = exports.runFullImportSchedule = exports.loadConnectedBots = exports.loadConnectedSockets = exports.loadInvoices = exports.loadSubscriptions = exports.loadSubscriptionOverview = exports.loadProfiles = exports.loadClients = exports.loadBots = exports.loadAdminUserInfos = exports.loadUsers = exports.loadUser = exports.loadDashboard = exports.loadR4cStatus = exports.loadMetricsStatus = exports.loadSaverStatus = exports.loadCollectorStatus = exports.resumeImport = exports.pauseImport = exports.getImportStatus = exports.cleanCacheCollector = exports.restartCollector = exports.cleanCacheR4c = exports.restartR4c = exports.cleanCacheMetrics = exports.restartMetrics = exports.deleteUiAlertStatus = exports.setUiAlertStatus = exports.getUiAlertStatus = exports.getR4cFactoringPrefetcherStatus = exports.switchFactoringPrefetcher = exports.getProblemEvents = exports.resolveNewProblemEvent = exports.deleteNewProblemEvent = exports.editProblemEvent = exports.createNewProblemEvent = exports.doctosyncApi = exports.triggerApi = exports.collectorApi = exports.r4cAdminApi = exports.r4cServerApi = exports.r2csvlicences = exports.caLicenceApi = exports.sponsoringApi = exports.profileApi = exports.stripeApi = exports.metricsAdminApi = exports.metricsApi = void 0;
exports.sendRestartBotCommand = exports.getRecentR4cData = exports.saveOnboardingData = exports.createMetricsStripeSubscription = exports.createR4CStripeSubscription = exports.setHelpdeskId = exports.createHelpdeskInfo = exports.mergeMetricsToR4cProfile = exports.mergeR4cToMetricsProfile = exports.profileLocationLookup = exports.clientLocations = exports.deleteClient = exports.triggerLeistungsautomatik = exports.triggerBenchmark = exports.triggerHeute = exports.triggerMetaUpdate = exports.triggerLeistungsbloecke = exports.triggerAbrechnungsautomat = exports.triggerPA = exports.triggerHkp = exports.triggerUmsatz = exports.triggerTerminbuch = exports.triggerTransformRohdaten = exports.triggerHkpmailer = exports.triggerTodomailer = exports.triggerFullImportCycle = exports.getR4cClientData = exports.triggerPraxisDataUpdate = exports.triggerFlushAVACache = exports.triggerFlushRouteCache = exports.triggerReloadR4cApps = exports.triggerMigrateDb = exports.triggerRebuildDb = exports.triggerFullCollectForOld = exports.triggerFullCollect = exports.triggerIncrementalCollect = exports.changeClientId = exports.setLastChangeTimestamp = exports.updateUser = exports.getUserActivity = exports.removeClientidFromUsers = exports.addMultiClientIdsToUsers = exports.changeUserMail = exports.createUserToken = exports.createNewUser = exports.deleteUser = exports.resetUserPassword = exports.deleteUserToken = exports.updateFactoringBackendStatus = exports.loadR4cStats = void 0;
exports.getCharlyAnalyticsLicences = exports.previewFormularPositionen = exports.setFormularConfigEntryPositionen = exports.getFormularRenderedUrl = exports.getEweFormularConfig = exports.sendEmails = exports.activateOnboardingEntry = exports.createStripeAccount4CID = exports.createStripeAccount4OnboardingEntry = exports.acceptOnboardingEntry = exports.deleteOnboardingEntry = exports.getOnboardingEntries = exports.getStripeCustomer = exports.getImportDetails = exports.loadAllImports = exports.getCurrentImportState = exports.loadAllImportProgress = exports.loadImportProgress = exports.getLogsEventsDetails = exports.getLogsEventsByHour = exports.getLogsEvents = exports.getProtocolCollectionStats = exports.getCollectionStats = exports.updateExternalIPBot = exports.updateBot = exports.sendChangeSSHPortCommand = exports.sendUpdateBotCommand = exports.sendRestartAutosshCommand = exports.sendRestartBotServiceCommand = void 0;
const login_state_1 = require("@/state/login.state");
const common_ui_1 = require("../../../common-ui");
const doctosyncApi_1 = require("@rose/common-ui/src/api/doctosyncApi");
const authRequestStateGetter = () => {
    var _a, _b;
    return ({
        authToken: (_a = login_state_1.loginStore.user) === null || _a === void 0 ? void 0 : _a.token,
        cid: (_b = login_state_1.loginStore.user) === null || _b === void 0 ? void 0 : _b.cid,
    });
};
const { roseApi, backends: { metricsBackend, collectorBackend }, } = (0, common_ui_1.createRoseApiWithAxios)(authRequestStateGetter);
function responseSuccessInterceptor(response) {
    return response;
}
const UNAUTHORIZED_STATUS_CODE = 401;
async function responseErrorInterceptor(error) {
    // if user is not authorized
    if (error.response && error.response.status === UNAUTHORIZED_STATUS_CODE) {
        // log user out
        login_state_1.loginStore.logout();
    }
    return Promise.reject(error);
}
metricsBackend.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
collectorBackend.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
exports.metricsApi = (0, common_ui_1.createMetricsApi)(roseApi);
exports.metricsAdminApi = exports.metricsApi.admin;
exports.stripeApi = exports.metricsApi.stripe;
exports.profileApi = exports.metricsApi.profile;
exports.sponsoringApi = exports.metricsApi.sponsoring;
exports.caLicenceApi = exports.metricsApi.calicences;
exports.r2csvlicences = exports.metricsApi.r2csvlicences;
exports.r4cServerApi = (0, common_ui_1.createR4cApi)(roseApi);
exports.r4cAdminApi = exports.r4cServerApi.admin;
exports.collectorApi = (0, common_ui_1.createCollectorApi)(roseApi);
exports.triggerApi = exports.collectorApi.trigger;
exports.doctosyncApi = (0, doctosyncApi_1.createDoctosyncApi)(roseApi);
// status
// status
const createNewProblemEvent = (event) => roseApi.metrics.post('uistatus/createNewProblemEvent', { event });
exports.createNewProblemEvent = createNewProblemEvent;
const editProblemEvent = (event) => roseApi.metrics.put('uistatus/editProblemEvent', { event });
exports.editProblemEvent = editProblemEvent;
const deleteNewProblemEvent = (eventId) => roseApi.metrics.delete('uistatus/deleteProblemEvent', { eventId });
exports.deleteNewProblemEvent = deleteNewProblemEvent;
const resolveNewProblemEvent = (eventId) => roseApi.metrics.post('uistatus/resolveProblemEvent', { eventId });
exports.resolveNewProblemEvent = resolveNewProblemEvent;
const getProblemEvents = () => roseApi.metrics.get('uistatus/problemEvents');
exports.getProblemEvents = getProblemEvents;
const switchFactoringPrefetcher = (r4chost, on) => roseApi.r4c.put(r4chost, `admin/prefetcher/${on}`);
exports.switchFactoringPrefetcher = switchFactoringPrefetcher;
const getR4cFactoringPrefetcherStatus = (r4chost) => roseApi.r4c.get(r4chost, `admin/prefetcher/`);
exports.getR4cFactoringPrefetcherStatus = getR4cFactoringPrefetcherStatus;
const getUiAlertStatus = () => roseApi.metrics.get('uistatus');
exports.getUiAlertStatus = getUiAlertStatus;
const setUiAlertStatus = (text) => roseApi.metrics.post('uistatus', { id: 'metrics', message: text, status: 'NOK' });
exports.setUiAlertStatus = setUiAlertStatus;
const deleteUiAlertStatus = () => roseApi.metrics.delete('uistatus', { id: 'metrics' });
exports.deleteUiAlertStatus = deleteUiAlertStatus;
const restartMetrics = () => roseApi.metrics.get('admin/exit');
exports.restartMetrics = restartMetrics;
const cleanCacheMetrics = () => roseApi.metrics.delete('admin/caches');
exports.cleanCacheMetrics = cleanCacheMetrics;
const restartR4c = (r4chost) => roseApi.r4c.get(r4chost, 'admin/exit');
exports.restartR4c = restartR4c;
const cleanCacheR4c = (r4chost) => roseApi.r4c.delete(r4chost, 'admin/cache');
exports.cleanCacheR4c = cleanCacheR4c;
const restartCollector = () => roseApi.collector.get('admin/exit');
exports.restartCollector = restartCollector;
const cleanCacheCollector = () => roseApi.collector.delete('admin/caches');
exports.cleanCacheCollector = cleanCacheCollector;
// dashboard
// dashboard
const getImportStatus = () => roseApi.collector.get('api/statusimport');
exports.getImportStatus = getImportStatus;
const pauseImport = () => roseApi.collector.get('api/pauseincimport');
exports.pauseImport = pauseImport;
const resumeImport = () => roseApi.collector.get('api/resumeincimport');
exports.resumeImport = resumeImport;
const loadCollectorStatus = () => roseApi.collector.get('status');
exports.loadCollectorStatus = loadCollectorStatus;
const loadSaverStatus = () => roseApi.saver.get('status');
exports.loadSaverStatus = loadSaverStatus;
const loadMetricsStatus = () => roseApi.metrics.get(`status`);
exports.loadMetricsStatus = loadMetricsStatus;
const loadR4cStatus = (r4chost) => roseApi.r4c.get(r4chost, `status`);
exports.loadR4cStatus = loadR4cStatus;
const loadDashboard = () => roseApi.metrics.get('admin/dashboard');
exports.loadDashboard = loadDashboard;
const loadUser = (email) => roseApi.metrics.get(`admin/user/${email}`);
exports.loadUser = loadUser;
const loadUsers = () => roseApi.metrics.get('admin/allusers');
exports.loadUsers = loadUsers;
const loadAdminUserInfos = () => roseApi.metrics.get('admin/allusersinfo');
exports.loadAdminUserInfos = loadAdminUserInfos;
const loadBots = () => roseApi.metrics.get('admin/bots');
exports.loadBots = loadBots;
const loadClients = () => roseApi.metrics.get('admin/clients');
exports.loadClients = loadClients;
const loadProfiles = () => roseApi.metrics.get('api/profiles');
exports.loadProfiles = loadProfiles;
const loadSubscriptionOverview = () => roseApi.metrics.get('admin/subscriptionOverview');
exports.loadSubscriptionOverview = loadSubscriptionOverview;
const loadSubscriptions = () => roseApi.metrics.get('admin/subscriptions');
exports.loadSubscriptions = loadSubscriptions;
const loadInvoices = () => roseApi.metrics.get('admin/invoices');
exports.loadInvoices = loadInvoices;
const loadConnectedSockets = (r4chost) => roseApi.r4c.get(r4chost, 'admin/connectedsockets');
exports.loadConnectedSockets = loadConnectedSockets;
const loadConnectedBots = () => roseApi.collector.get('api/bots');
exports.loadConnectedBots = loadConnectedBots;
const runFullImportSchedule = () => roseApi.collector.get('api/runfullimportschedule');
exports.runFullImportSchedule = runFullImportSchedule;
const loadR4cFactoringBackendStatus = (r4chost) => roseApi.r4c.get(r4chost, `admin/status`);
exports.loadR4cFactoringBackendStatus = loadR4cFactoringBackendStatus;
const loadEweStats = () => roseApi.r4cDispatch.get('admin/ewestats');
exports.loadEweStats = loadEweStats;
const loadR4cStats = () => roseApi.r4cDispatch.get('admin/r4cstats');
exports.loadR4cStats = loadR4cStats;
const updateFactoringBackendStatus = (r4chost, backend, enabled) => roseApi.r4c.put(r4chost, `admin/status/${backend}/${enabled}`);
exports.updateFactoringBackendStatus = updateFactoringBackendStatus;
// user
// user
exports.deleteUserToken = exports.metricsApi.auth.deleteToken;
exports.resetUserPassword = exports.metricsApi.auth.resetPassword;
exports.deleteUser = exports.metricsApi.admin.deleteUserCompletely;
const createNewUser = (newUser) => roseApi.metrics.post(`/auth/user`, newUser);
exports.createNewUser = createNewUser;
const createUserToken = (userEmail) => roseApi.metrics.get(`/admin/token/${userEmail}?expiresInHours=1`);
exports.createUserToken = createUserToken;
const changeUserMail = (cid, userMail, newUserMail) => roseApi.metrics.post(`/auth/changeusermail`, { userMail, newUserMail }, { clientIdHeader: cid });
exports.changeUserMail = changeUserMail;
const addMultiClientIdsToUsers = (userMails, clientIds) => roseApi.metrics.post(`/admin/addclientidstousers`, { userMails, clientIds });
exports.addMultiClientIdsToUsers = addMultiClientIdsToUsers;
const removeClientidFromUsers = (userMails, cid) => roseApi.metrics.post(`/admin/removeclientidfromusers`, { userMails, cid });
exports.removeClientidFromUsers = removeClientidFromUsers;
const getUserActivity = (userEmail) => roseApi.metrics.get(`/admin/useractivity/${userEmail}`);
exports.getUserActivity = getUserActivity;
const updateUser = (updatedUser) => roseApi.metrics.put(`/auth/user`, updatedUser);
exports.updateUser = updateUser;
// client
// client
const setLastChangeTimestamp = (cid, timestamp) => roseApi.metrics.put(`/admin/setlastchange/${timestamp}`, { clientIdHeader: cid });
exports.setLastChangeTimestamp = setLastChangeTimestamp;
const changeClientId = (cid, newClientId, raw = false) => roseApi.metrics.post(`/admin/changeclientid/`, { newClientId }, { clientIdHeader: cid, raw });
exports.changeClientId = changeClientId;
const triggerIncrementalCollect = (cid, raw = false) => roseApi.collector.get(`/api/startimport/true`, { raw, clientIdHeader: cid });
exports.triggerIncrementalCollect = triggerIncrementalCollect;
const triggerFullCollect = (cid, raw = false) => roseApi.collector.get(`/api/startimport/false`, { raw, clientIdHeader: cid });
exports.triggerFullCollect = triggerFullCollect;
const triggerFullCollectForOld = () => roseApi.collector.get(`/api/runfullimportschedule?tooold=true`);
exports.triggerFullCollectForOld = triggerFullCollectForOld;
const triggerRebuildDb = (cid, raw = false) => roseApi.metrics.get(`/admin/rebuilddb/${cid}`, { raw, clientIdHeader: cid });
exports.triggerRebuildDb = triggerRebuildDb;
const triggerMigrateDb = (cid, raw = false) => roseApi.metrics.get(`/admin/migratedb/${cid}`, { raw, clientIdHeader: cid });
exports.triggerMigrateDb = triggerMigrateDb;
const triggerReloadR4cApps = (cid, r4chost, raw = false) => roseApi.r4c.get(r4chost, `/admin/reloadr4c`, { raw, clientIdHeader: cid });
exports.triggerReloadR4cApps = triggerReloadR4cApps;
const triggerFlushRouteCache = (cid, r4chost, raw = false) => roseApi.r4c.delete(r4chost, `/admin/cache/routes`, null, { raw, clientIdHeader: cid });
exports.triggerFlushRouteCache = triggerFlushRouteCache;
const triggerFlushAVACache = (cid, r4chost, raw = false) => roseApi.r4c.delete(r4chost, `/admin/cache/ava`, null, { raw, clientIdHeader: cid });
exports.triggerFlushAVACache = triggerFlushAVACache;
const triggerPraxisDataUpdate = (cid, r4chost, raw = false) => roseApi.r4c.get(r4chost, `/admin/updatepraxisdata`, { raw, clientIdHeader: cid });
exports.triggerPraxisDataUpdate = triggerPraxisDataUpdate;
const getR4cClientData = (cid, r4chost, raw = false) => roseApi.r4c.get(r4chost, `/admin/fromr4c/clientdata`, { raw, clientIdHeader: cid });
exports.getR4cClientData = getR4cClientData;
const triggerFullImportCycle = (raw = false) => roseApi.collector.get(`/api/runfullimportschedule`, { raw });
exports.triggerFullImportCycle = triggerFullImportCycle;
const triggerTodomailer = (raw = false) => roseApi.metrics.get(`/trigger/todomailer`, { raw });
exports.triggerTodomailer = triggerTodomailer;
const triggerHkpmailer = (raw = false) => roseApi.metrics.get(`/trigger/hkpmailer`, { raw });
exports.triggerHkpmailer = triggerHkpmailer;
const triggerTransformRohdaten = (cid, useImportTables, raw = false) => roseApi.collector.get(`/api/trigger/transformrohdaten/${useImportTables}`, { raw, clientIdHeader: cid });
exports.triggerTransformRohdaten = triggerTransformRohdaten;
const triggerTerminbuch = (cid, raw = false) => roseApi.collector.get(`/api/trigger/terminbuch`, { raw, clientIdHeader: cid });
exports.triggerTerminbuch = triggerTerminbuch;
const triggerUmsatz = (cid, raw = false) => roseApi.collector.get(`/api/trigger/umsatz`, { raw, clientIdHeader: cid });
exports.triggerUmsatz = triggerUmsatz;
const triggerHkp = (cid, raw = false) => roseApi.collector.get(`/api/trigger/hkp`, { raw, clientIdHeader: cid });
exports.triggerHkp = triggerHkp;
const triggerPA = (cid, raw = false) => roseApi.collector.get(`/api/trigger/pa`, { raw, clientIdHeader: cid });
exports.triggerPA = triggerPA;
const triggerAbrechnungsautomat = (cid, raw = false) => roseApi.collector.get(`/api/trigger/abrechnungsautomat`, {
    raw,
    clientIdHeader: cid,
});
exports.triggerAbrechnungsautomat = triggerAbrechnungsautomat;
const triggerLeistungsbloecke = (cid, raw = false) => roseApi.collector.get(`/api/trigger/leistungsbloecke`, {
    raw,
    clientIdHeader: cid,
});
exports.triggerLeistungsbloecke = triggerLeistungsbloecke;
const triggerMetaUpdate = (cid) => roseApi.metrics.get(`/api/profile/updatemeta`, {
    clientIdHeader: cid,
});
exports.triggerMetaUpdate = triggerMetaUpdate;
const triggerHeute = (cid, days, raw = false) => roseApi.collector.get(`/api/trigger/heute?days=${days}`, {
    raw,
    clientIdHeader: cid,
});
exports.triggerHeute = triggerHeute;
const triggerBenchmark = (cid, raw = false) => roseApi.collector.get(`/api/trigger/benchmark`, {
    raw,
    clientIdHeader: cid,
});
exports.triggerBenchmark = triggerBenchmark;
const triggerLeistungsautomatik = (cid) => roseApi.collector.get(`/api/trigger/leistungsautomatik`, {
    clientIdHeader: cid,
});
exports.triggerLeistungsautomatik = triggerLeistungsautomatik;
const deleteClient = (cid) => roseApi.metrics.delete(`admin/client/${cid}`);
exports.deleteClient = deleteClient;
const clientLocations = () => exports.metricsApi.profile.getProfilesWithLocation();
exports.clientLocations = clientLocations;
const profileLocationLookup = (cid) => exports.metricsAdminApi.profileLocationLookup(cid);
exports.profileLocationLookup = profileLocationLookup;
const mergeR4cToMetricsProfile = (r4cClientid, metricsClientid) => exports.metricsAdminApi.mergeR4cToMetricsProfile(r4cClientid, metricsClientid);
exports.mergeR4cToMetricsProfile = mergeR4cToMetricsProfile;
const mergeMetricsToR4cProfile = (metricsClientid, r4cClientid) => exports.metricsAdminApi.mergeMetricsToR4cProfile(metricsClientid, r4cClientid);
exports.mergeMetricsToR4cProfile = mergeMetricsToR4cProfile;
const createHelpdeskInfo = (cid) => exports.profileApi.createHelpdeskInfo(cid);
exports.createHelpdeskInfo = createHelpdeskInfo;
const setHelpdeskId = (cid, helpdeskId) => exports.profileApi.setHelpdeskId(cid, helpdeskId);
exports.setHelpdeskId = setHelpdeskId;
const createR4CStripeSubscription = (cid, factoringFeature, trialDays, startDate, additionalFeatures = []) => exports.stripeApi.createR4CStripeSubscription(cid, factoringFeature, trialDays, startDate, additionalFeatures);
exports.createR4CStripeSubscription = createR4CStripeSubscription;
const createMetricsStripeSubscription = (cid, stripeCustomerId, startDate, gutscheincode, multiclientlogin, savesetupfee) => exports.stripeApi.createMetricsStripeSubscription(cid, stripeCustomerId, startDate, gutscheincode, multiclientlogin, savesetupfee);
exports.createMetricsStripeSubscription = createMetricsStripeSubscription;
const saveOnboardingData = (cid, onboardingData) => roseApi.metrics.post('admin/saveOnboardingData', {
    cid,
    onboardingData,
});
exports.saveOnboardingData = saveOnboardingData;
const getRecentR4cData = (cid) => exports.r4cAdminApi.getRecentData(cid);
exports.getRecentR4cData = getRecentR4cData;
// bot
// bot
const sendRestartBotCommand = (cid) => roseApi.collector.get(`api/reboot`, { clientIdHeader: cid });
exports.sendRestartBotCommand = sendRestartBotCommand;
const sendRestartBotServiceCommand = (cid) => roseApi.collector.get(`api/restartservice`, { clientIdHeader: cid });
exports.sendRestartBotServiceCommand = sendRestartBotServiceCommand;
const sendRestartAutosshCommand = (cid) => roseApi.collector.get(`api/restartsshservice`, { clientIdHeader: cid });
exports.sendRestartAutosshCommand = sendRestartAutosshCommand;
const sendUpdateBotCommand = (cid) => roseApi.collector.get(`api/update`, { clientIdHeader: cid });
exports.sendUpdateBotCommand = sendUpdateBotCommand;
const sendChangeSSHPortCommand = (cid, port) => roseApi.collector.get(`api/changesshport/${port}`, { clientIdHeader: cid });
exports.sendChangeSSHPortCommand = sendChangeSSHPortCommand;
const updateBot = (updatedBot) => roseApi.metrics.put(`/admin/bot`, updatedBot);
exports.updateBot = updateBot;
const updateExternalIPBot = (cid) => roseApi.collector.get(`api/updateexternalip/${cid}`);
exports.updateExternalIPBot = updateExternalIPBot;
// import
// import
const getCollectionStats = (cid, fullCollectionStatsData, incrementalCollectionStatsData, from, to, limit, excludeprotocol) => roseApi.metrics.get(`api/collectionstats`, {
    query: {
        full: fullCollectionStatsData,
        incremental: incrementalCollectionStatsData,
        from: from === null || from === void 0 ? void 0 : from.toString(),
        to: to === null || to === void 0 ? void 0 : to.toString(),
        limit: limit === null || limit === void 0 ? void 0 : limit.toString(),
        excludeprotocol: excludeprotocol,
    },
    clientIdHeader: cid,
});
exports.getCollectionStats = getCollectionStats;
const getProtocolCollectionStats = (cid, id) => roseApi.metrics.get(`api/protocolcollectionstats`, {
    query: { id: id },
    clientIdHeader: cid,
});
exports.getProtocolCollectionStats = getProtocolCollectionStats;
const getLogsEvents = (date) => roseApi.metrics.get(`/logevents/${date}`);
exports.getLogsEvents = getLogsEvents;
const getLogsEventsByHour = (date, hour) => roseApi.metrics.get(`/logevents/${date}/${hour}`);
exports.getLogsEventsByHour = getLogsEventsByHour;
const getLogsEventsDetails = (id) => roseApi.metrics.get(`/logeventsdetails/${id}`);
exports.getLogsEventsDetails = getLogsEventsDetails;
const loadImportProgress = (cid) => roseApi.collector.get(`api/importprogress/`, { clientIdHeader: cid });
exports.loadImportProgress = loadImportProgress;
const loadAllImportProgress = (includelast = false) => roseApi.collector.get(`api/allimportprogress/`, { query: { includelast } });
exports.loadAllImportProgress = loadAllImportProgress;
const getCurrentImportState = (limitErrors) => roseApi.metrics.get(`admin/currentimportstate/`, { query: { limitErrors } });
exports.getCurrentImportState = getCurrentImportState;
const loadAllImports = (fullImportData, incrementalImportData, limit) => roseApi.metrics.get(`admin/imports/`, {
    query: {
        full: fullImportData,
        incremental: incrementalImportData,
        limit: limit === null || limit === void 0 ? void 0 : limit.toString(),
    },
});
exports.loadAllImports = loadAllImports;
const getImportDetails = (importId) => roseApi.metrics.get(`admin/imports/${importId}`);
exports.getImportDetails = getImportDetails;
// onboarding
// onboarding
const getStripeCustomer = (id) => exports.stripeApi.loadStripeCustomer(id);
exports.getStripeCustomer = getStripeCustomer;
const getOnboardingEntries = () => roseApi.metrics.get(`admin/onboarding`);
exports.getOnboardingEntries = getOnboardingEntries;
const deleteOnboardingEntry = (id) => roseApi.metrics.delete(`admin/onboarding`, { id });
exports.deleteOnboardingEntry = deleteOnboardingEntry;
const acceptOnboardingEntry = (id, cid, dbHost, existingCustomer, reuseExistingStripeCustomer, addSuffix) => roseApi.metrics.post(`admin/onboarding/accept`, {
    id,
    cid,
    dbHost,
    existingCustomer,
    reuseExistingStripeCustomer,
    addSuffix,
});
exports.acceptOnboardingEntry = acceptOnboardingEntry;
const createStripeAccount4OnboardingEntry = (id, reuseExistingStripeCustomer) => exports.stripeApi.createStripeAccount4OnboardingEntry(id, reuseExistingStripeCustomer);
exports.createStripeAccount4OnboardingEntry = createStripeAccount4OnboardingEntry;
const createStripeAccount4CID = (cid, reuseExistingStripeCustomer) => exports.stripeApi.createStripeAccount4CID(cid, reuseExistingStripeCustomer);
exports.createStripeAccount4CID = createStripeAccount4CID;
const activateOnboardingEntry = (id, reuseExistingStripeCustomer) => roseApi.metrics.post(`admin/onboarding/activate`, { id, reuseExistingStripeCustomer });
exports.activateOnboardingEntry = activateOnboardingEntry;
// email
// email
const sendEmails = (entry) => roseApi.metrics.post(`admin/sendemail`, entry);
exports.sendEmails = sendEmails;
// formular editor
// formular editor
const getEweFormularConfig = () => roseApi.r4cDispatch.get('admin/formulare/config');
exports.getEweFormularConfig = getEweFormularConfig;
const getFormularRenderedUrl = (formularId) => roseApi.r4cDispatch.get(`admin/formulare/rendered/${formularId}`);
exports.getFormularRenderedUrl = getFormularRenderedUrl;
const setFormularConfigEntryPositionen = (update) => roseApi.r4cDispatch.post(`admin/formulare/config/positionen`, update);
exports.setFormularConfigEntryPositionen = setFormularConfigEntryPositionen;
const previewFormularPositionen = (formularId, positionen) => roseApi.r4cDispatch.post(`admin/formulare/preview/${formularId}`, { positionen });
exports.previewFormularPositionen = previewFormularPositionen;
// charly analytics licences
const getCharlyAnalyticsLicences = () => roseApi.metrics.get(`/admin/calicences`);
exports.getCharlyAnalyticsLicences = getCharlyAnalyticsLicences;
