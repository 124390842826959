"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const data_1 = require("@/services/data");
const vue_1 = require("vue");
const helpers_1 = require("@/services/helpers");
exports.default = (0, vue_1.defineComponent)({
    name: 'ClientDoctosync',
    props: {
        isSolutioTeam: Boolean,
        selectedClient: { type: Object, default: null },
    },
    data() {
        return {
            isLoading: false,
            hasError: false,
            error: {},
            settings: {},
            meta: {},
        };
    },
    computed: {
        errorText() {
            var _a, _b;
            return (_b = (_a = this.error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message;
        },
        doctolibActiveLabel() {
            return this.doctolibActive ? 'Aktiv' : 'Inaktiv';
        },
        doctolibActive: {
            get() {
                var _a, _b, _c, _d;
                console.log('doctolibActive', (_b = (_a = this.settings) === null || _a === void 0 ? void 0 : _a.doctolib) === null || _b === void 0 ? void 0 : _b.active);
                return (_d = (_c = this.settings) === null || _c === void 0 ? void 0 : _c.doctolib) === null || _d === void 0 ? void 0 : _d.active;
            },
            set(value) {
                if (this.settings) {
                    this.settings.doctolib.active = value;
                }
            },
        },
        doctolibKey: {
            get() {
                var _a;
                return (_a = this.settings) === null || _a === void 0 ? void 0 : _a.doctolib.key;
            },
            set(value) {
                if (this.settings) {
                    this.settings.doctolib.key = value;
                }
            },
        },
        doctolibSecretKey: {
            get() {
                var _a;
                return (_a = this.settings) === null || _a === void 0 ? void 0 : _a.doctolib.secretKey;
            },
            set(value) {
                if (this.settings) {
                    this.settings.doctolib.secretKey = value;
                }
            },
        },
    },
    watch: {
        selectedClient: {
            async handler(oldValue, newValue) {
                if ((oldValue === null || oldValue === void 0 ? void 0 : oldValue.cid) !== (newValue === null || newValue === void 0 ? void 0 : newValue.cid)) {
                    await this.init();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    async beforeMount() {
        await this.init();
    },
    methods: {
        formatDate: helpers_1.formatDate,
        async init() {
            try {
                console.log('init::doctosync');
                this.isLoading = true;
                this.hasError = false;
                if (this.selectedClient.cid) {
                    this.settings = await data_1.metricsApi.admin.loadClientSettings(this.selectedClient.cid);
                    this.meta = await data_1.doctosyncApi.getMeta(this.selectedClient.cid);
                }
            }
            catch (e) {
                console.error(e);
                this.hasError = true;
                this.error = e;
            }
            finally {
                this.isLoading = false;
            }
        },
        async save() {
            var _a;
            this.isLoading = true;
            if (this.settings) {
                const settings = this.settings;
                await (0, helpers_1.handleApiCall)({
                    apiFunction: () => data_1.metricsApi.admin.updateClientSettings(this.selectedClient.cid, settings),
                    successMessage: `Doctosync-Einstellungen für ${(_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid} wurden aktualisiert`,
                });
            }
            this.isLoading = false;
        },
        async reset(hard) {
            var _a;
            this.isLoading = true;
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => data_1.doctosyncApi.reset(this.selectedClient.cid, hard),
                successMessage: `Doctosync für ${(_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid} wurde zurückgesetzt`,
            });
            await this.init();
            this.isLoading = false;
        },
    },
});
