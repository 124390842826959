"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const highcharts_vue_1 = require("highcharts-vue");
const lodash_1 = require("lodash");
const base_1 = require("../../../base");
exports.default = (0, vue_1.defineComponent)({
    name: 'ImportChartComponent',
    components: {
        Chart: highcharts_vue_1.Chart,
    },
    props: {
        importInfos: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        panel: [0],
        normalRangeFull: [],
        normalRangeInc: [],
        fullChartOptions: {
            chart: {
                type: 'scatter',
            },
            title: {
                text: 'Full Duration Distribution by PVS',
            },
            xAxis: {
                type: 'category',
                categories: [],
            },
            yAxis: {
                type: 'logarithmic',
                title: {
                    text: 'Duration (m)',
                },
                labels: {
                    format: '{value} m',
                },
                plotBands: [],
            },
            legend: {},
            plotOptions: {
                scatter: {
                    showInLegend: false,
                    jitter: {
                        x: 0.24,
                        y: 0,
                    },
                    marker: {
                        radius: 2,
                        symbol: 'circle',
                    },
                    tooltip: {
                        pointFormat: '{point.name}: {point.y:.2f}m',
                    },
                },
            },
            series: [],
        },
        incChartOptions: {
            chart: {
                type: 'scatter',
            },
            title: {
                text: 'Incremental Duration Distribution by PVS',
            },
            xAxis: {
                type: 'category',
                categories: [],
            },
            yAxis: {
                type: 'logarithmic',
                title: {
                    text: 'Duration (m)',
                },
                labels: {
                    format: '{value} m',
                },
                plotBands: [],
            },
            legend: {},
            plotOptions: {
                scatter: {
                    showInLegend: false,
                    jitter: {
                        x: 0.24,
                        y: 0,
                    },
                    marker: {
                        radius: 2,
                        symbol: 'circle',
                    },
                    tooltip: {
                        pointFormat: '{point.name}: {point.y:.2f}m',
                    },
                },
            },
            series: [],
        },
    }),
    watch: {
        importInfos: {
            handler() {
                this.updateCharts();
            },
            deep: true,
        },
    },
    mounted() {
        this.updateCharts();
    },
    methods: {
        getQuantile(arr, quantile) {
            const sorted = (0, lodash_1.sortBy)(arr);
            const pos = (sorted.length - 1) * quantile;
            const base = Math.floor(pos);
            const rest = pos - base;
            if (sorted[base + 1] !== undefined) {
                return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
            }
            else {
                return sorted[base];
            }
        },
        updateCharts() {
            const imports = (0, lodash_1.chain)(this.importInfos)
                .map((value, key) => {
                var _a, _b;
                return ({
                    cid: key,
                    pvs: value.pvs,
                    full: ((_a = value.full) === null || _a === void 0 ? void 0 : _a.duration) ? base_1.roseDayjs.duration(value.full.duration, 's').asMinutes() : 0,
                    inc: ((_b = value.incremental) === null || _b === void 0 ? void 0 : _b.duration) ? base_1.roseDayjs.duration(value.incremental.duration, 's').asMinutes() : 0,
                });
            })
                .groupBy(cid => cid.pvs)
                .value();
            console.log('imports:', imports);
            this.fullChartOptions.xAxis.categories = Object.keys(imports);
            this.incChartOptions.xAxis.categories = Object.keys(imports);
            this.fullChartOptions.series = [];
            this.incChartOptions.series = [];
            let x = 0;
            (0, lodash_1.forEach)(imports, (value, key) => {
                this.fullChartOptions.series.push({
                    name: key,
                    data: value.map(item => ({ name: item.cid, x, y: item.full })),
                });
                this.incChartOptions.series.push({
                    name: key,
                    data: value.map(item => ({ name: item.cid, x, y: item.inc })),
                });
                x += 1;
            });
            this.abnormal();
        },
        abnormal() {
            const groupedData = (0, lodash_1.chain)(this.importInfos)
                .map((value, key) => {
                var _a, _b;
                return ({
                    cid: key,
                    pvs: value.pvs,
                    full: ((_a = value.full) === null || _a === void 0 ? void 0 : _a.duration) ? base_1.roseDayjs.duration(value.full.duration, 's').asMinutes() : 0,
                    inc: ((_b = value.incremental) === null || _b === void 0 ? void 0 : _b.duration) ? base_1.roseDayjs.duration(value.incremental.duration, 's').asMinutes() : 0,
                });
            })
                .groupBy('pvs')
                .value();
            const normalRangesPerPvs = (0, lodash_1.mapValues)(groupedData, group => {
                const fullDurations = group.map(d => d.full).filter(d => d > 0);
                const incDurations = group.map(d => d.inc).filter(d => d > 0);
                const fullQuantiles = {
                    q25: this.getQuantile(fullDurations, 0.01),
                    q75: this.getQuantile(fullDurations, 0.95),
                };
                const incQuantiles = {
                    q25: this.getQuantile(incDurations, 0.01),
                    q75: this.getQuantile(incDurations, 0.95),
                };
                return {
                    full: {
                        from: fullQuantiles.q25,
                        to: fullQuantiles.q75,
                    },
                    inc: {
                        from: incQuantiles.q25,
                        to: incQuantiles.q75,
                    },
                };
            });
            this.normalRangeInc = (0, lodash_1.map)(normalRangesPerPvs, (value, key) => ({
                pvs: key,
                from: Math.round(value.inc.from * 100) / 100,
                to: Math.round(value.inc.to * 100) / 100,
            }));
            this.normalRangeFull = (0, lodash_1.map)(normalRangesPerPvs, (value, key) => ({
                pvs: key,
                from: Math.round(value.full.from * 100) / 100,
                to: Math.round(value.full.to * 100) / 100,
            }));
            console.log('Normal Ranges per PVS:', normalRangesPerPvs);
            (0, lodash_1.forEach)(this.fullChartOptions.series, series => {
                series.data = series.data.map((point) => {
                    const normalRange = normalRangesPerPvs[series.name].full;
                    const isAbnormal = normalRange && (point.y < normalRange.from || point.y > normalRange.to);
                    return Object.assign(Object.assign({}, point), { color: isAbnormal ? '#FFA500' : undefined });
                });
            });
            (0, lodash_1.forEach)(this.incChartOptions.series, series => {
                series.data = series.data.map((point) => {
                    const normalRange = normalRangesPerPvs[series.name].inc;
                    const isAbnormal = normalRange && (point.y < normalRange.from || point.y > normalRange.to);
                    return Object.assign(Object.assign({}, point), { color: isAbnormal ? '#FFA500' : undefined });
                });
            });
            console.log('Updated Series Data with Abnormal Points:', this.fullChartOptions.series, this.incChartOptions.series);
        },
    },
});
