"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientsColumns = exports.r4cColumns = exports.metricsColumns = void 0;
const base_1 = require("@/../../base");
const common_ui_1 = require("@rose/common-ui");
const getZaPzr = (params) => {
    var _a;
    const mb = (_a = params.data) === null || _a === void 0 ? void 0 : _a.meta.aktiveBehandler;
    if ((mb === null || mb === void 0 ? void 0 : mb.za) >= 0) {
        return `${mb.za} / ${mb.pzr} (${mb.billable} / ${mb.billableRounded})`;
    }
    return '';
};
const getStripeLicenceInfo = (params) => {
    var _a;
    const ms = (_a = params.data) === null || _a === void 0 ? void 0 : _a.meta.stripe;
    if (ms.metricsType === 'volume') {
        return `v ${ms.metricsBehandlerLizenzen}`;
    }
    return `${ms.metricsBehandlerLizenzen}`;
};
const getPVS = (params) => {
    var _a;
    if ((0, base_1.hasAnyR4cFeature)(params.data)) {
        return 'charly';
    }
    return (_a = params.data) === null || _a === void 0 ? void 0 : _a.meta.pvsInfo.typ;
};
const getPVSVersion = (params) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return (((_b = (_a = params.data) === null || _a === void 0 ? void 0 : _a.meta.pvsInfo) === null || _b === void 0 ? void 0 : _b.version) && ((_d = (_c = params.data) === null || _c === void 0 ? void 0 : _c.meta.pvsInfo) === null || _d === void 0 ? void 0 : _d.version) !== '-'
        ? (_f = (_e = params.data) === null || _e === void 0 ? void 0 : _e.meta.pvsInfo) === null || _f === void 0 ? void 0 : _f.version
        : (_h = (_g = params.data) === null || _g === void 0 ? void 0 : _g.metar4c.pvsInfo) === null || _h === void 0 ? void 0 : _h.version) || '-';
};
const getLastActiveUsersCount = (params) => params.data.meta.lastActiveUsers.length;
exports.metricsColumns = [
    'fullimportavg',
    'lastActive',
    'activeUsers',
    'lastOnline',
    'import',
    'uptime',
    'version',
    'ipExternal',
    'ipInternal',
    'monitor',
    'active',
    'user',
    'patienten',
    'termine',
];
exports.r4cColumns = [
    'r4cPatienten',
    'r4cTermine',
    'r4cPatienten',
    'r4cTermine',
    'r4cHost',
    'r4cCount',
    'tablets',
    'r4cRz',
    'r4cVertraege',
    'r4cLastEwe',
    'r4cEweCount',
    'r4cClicks',
    'r4cSignedDocuments',
];
function clientsColumns(smallScreen) {
    return [
        {
            headerName: 'Client-ID',
            field: 'cid',
            cellRenderer: 'ClientsClientIdCell',
            sort: 'asc',
            headerTooltip: 'Eindeutige ID des Kunden',
            width: 350,
            pinned: smallScreen ? undefined : 'left',
        },
        {
            headerName: 'Users',
            valueGetter: params => { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a.cid; },
            cellRenderer: 'GoToUsersCell',
            cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
            width: 40,
        },
        { headerName: 'Praxis', field: 'praxisKurzName', cellRenderer: 'ClientsClientIdCell', width: 300 },
        { headerName: 'Tags', field: 'tags', cellRenderer: 'TagsCell', headerTooltip: 'Tags', width: 250 },
        {
            headerName: 'Kunde seit',
            field: 'createdAt',
            headerTooltip: 'Kunde seit',
            valueFormatter: common_ui_1.dateFormatter,
            width: 100,
        },
        {
            headerName: 'Features',
            field: 'lizenzen',
            cellRenderer: 'FeaturesCell',
            headerTooltip: 'Features',
            flex: 1,
            minWidth: 150,
        },
        {
            headerName: 'ON',
            field: 'bot.online',
            cellRenderer: 'BooleanIconCell',
            headerTooltip: 'Online (Bot)',
            width: 50,
        },
        {
            headerName: 'SSH',
            field: 'bot.sshonline',
            cellRenderer: 'BooleanIconCell',
            headerTooltip: 'Status SSH-Tunnel',
            width: 50,
        },
        {
            headerName: 'PVS',
            valueGetter: getPVS,
            cellRenderer: 'PvsCell',
            headerTooltip: 'Art des PVS',
            width: 65,
        },
        {
            headerName: 'ø Full',
            colId: 'fullimportavg',
            field: 'meta.fullImportStatistics.avg',
            valueFormatter: common_ui_1.durationFormatter,
            headerTooltip: 'ø-Dauer Full-Import',
            width: 70,
        },
        {
            headerName: 'RZ',
            colId: 'r4cRz',
            field: 'rzkeys',
            cellRenderer: 'TagsCell',
            headerTooltip: 'Rechenzentren',
            width: 100,
        },
        {
            headerName: 'V-PVS',
            // field: 'meta.pvsInfo.version',
            valueGetter: getPVSVersion,
            headerTooltip: 'Version des PVS',
            width: 70,
        },
        {
            headerName: 'V-DB',
            field: 'dbInfo.version.short',
            headerTooltip: 'Version der Datenbank',
            width: 60,
        },
        { headerName: 'DB-Host', field: 'dbhost', valueFormatter: common_ui_1.hostnameFormatter, headerTooltip: 'DB-Host', width: 90 },
        {
            headerName: 'SSH-Port',
            field: 'bot.sshPort',
            cellRenderer: 'CopyTextCell',
            headerTooltip: 'SSH-Port',
            width: 60,
        },
        {
            headerName: 'SSH-Alias',
            field: 'bot.alias',
            cellRenderer: 'CopyTextCell',
            headerTooltip: 'SSH-Alias',
            width: 120,
        },
        {
            headerName: 'Group',
            valueGetter: params => { var _a, _b; return (_b = (_a = params.data) === null || _a === void 0 ? void 0 : _a.groupdata) === null || _b === void 0 ? void 0 : _b.name; },
            cellRenderer: 'CopyTextCell',
            headerTooltip: 'Group',
            width: 100,
        },
        {
            headerName: 'Verträge',
            colId: 'r4cVertraege',
            field: 'rzvertraege',
            cellRenderer: 'VertraegeCell',
            headerTooltip: 'Verträge',
            width: 180,
        },
        {
            headerName: 'DB-Size',
            field: 'meta.dbInfo.size',
            valueFormatter: common_ui_1.binarySizeFormatter,
            headerTooltip: 'DB-Size',
            width: 75,
        },
        { headerName: 'KZV', field: 'meta.screening.kzv', headerTooltip: 'KZV' },
        { headerName: 'BEL', field: 'meta.screening.laborbel', headerTooltip: 'BEL', width: 50 },
        { headerName: '#ZA/PZR', valueGetter: getZaPzr, headerTooltip: 'Anzahl ZA/PZR', width: 100 },
        {
            headerName: '#Stripe',
            valueGetter: getStripeLicenceInfo,
            comparator: (valueA, valueB, nodeA, nodeB) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                const msA = ((_c = (_b = (_a = nodeA.data) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.stripe) === null || _c === void 0 ? void 0 : _c.metricsBehandlerLizenzen) || -1;
                const msB = ((_f = (_e = (_d = nodeB.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.stripe) === null || _f === void 0 ? void 0 : _f.metricsBehandlerLizenzen) || -1;
                console.log('msA', msA, 'msB', msB);
                if (msA === msB) {
                    const vA = ((_j = (_h = (_g = nodeA.data) === null || _g === void 0 ? void 0 : _g.meta) === null || _h === void 0 ? void 0 : _h.stripe) === null || _j === void 0 ? void 0 : _j.metricsType) === 'volume';
                    const vB = ((_m = (_l = (_k = nodeB.data) === null || _k === void 0 ? void 0 : _k.meta) === null || _l === void 0 ? void 0 : _l.stripe) === null || _m === void 0 ? void 0 : _m.metricsType) === 'volume';
                    if (vA === vB) {
                        return 0;
                    }
                    return vA ? 1 : -1;
                }
                return msA - msB;
            },
            headerTooltip: 'Anzahl der Stripe-Behandler-Lizenzen',
            width: 60,
        },
        {
            headerName: 'Praxis-Location',
            valueGetter: (params) => { var _a, _b; return !!((_b = (_a = params.data) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.osmid); },
            cellRenderer: 'BooleanIconCell',
            headerTooltip: 'Praxis-Location',
            width: 40,
        },
        {
            headerName: 'Doctosync',
            valueGetter: (params) => { var _a, _b, _c, _d, _e; return ((_b = (_a = params.data) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.doctosync) && !((_e = (_d = (_c = params.data) === null || _c === void 0 ? void 0 : _c.meta) === null || _d === void 0 ? void 0 : _d.doctosync) === null || _e === void 0 ? void 0 : _e.error); },
            cellRenderer: 'BooleanIconCell',
            headerTooltip: 'Doctosync-Aktiv',
            width: 50,
        },
        { headerName: '#r4cs', colId: 'r4cCount', field: 'countR4C', headerTooltip: '#r4cs', width: 100 },
        {
            headerName: '#Tablets',
            colId: 'tablets',
            field: 'registeredTabletCount',
            headerTooltip: 'r4c Anzahl Tablets',
            width: 100,
        },
        {
            headerName: 'last Active',
            colId: 'lastActive',
            field: 'meta.lastActiveUsers',
            valueFormatter: common_ui_1.lastArrayFormatter,
            headerTooltip: 'Zuletzt Aktiv',
            width: 125,
        },
        {
            headerName: 'Last Online',
            colId: 'lastOnline',
            field: 'bot.updatedAt',
            valueFormatter: common_ui_1.dateTimeFormatter,
            cellClass: (params) => (0, common_ui_1.classHoursAlarmCss)(params.value, 1, 'bold-red'),
            headerTooltip: 'Zuletzt Online',
            width: 135,
        },
        {
            headerName: 'Import',
            colId: 'import',
            field: 'importInfos',
            cellRenderer: 'ClientImportCell',
            headerTooltip: 'Import',
            width: 70,
        },
        {
            headerName: 'Uptime',
            colId: 'uptime',
            field: 'bot.uptime',
            valueFormatter: common_ui_1.fromNowWoSuffixFormatter,
            cellClass: (params) => (0, common_ui_1.classHoursAlarmCss)(params.value, 24, 'bold-red', true),
            headerTooltip: 'Uptime Bot',
            width: 90,
        },
        {
            headerName: 'V-Bot',
            colId: 'version',
            field: 'bot.version',
            cellRenderer: 'BotVersionCell',
            headerTooltip: 'Bot-Version',
            width: 135,
        },
        {
            headerName: 'IP Extern',
            colId: 'ipExternal',
            field: 'bot.ipExternal',
            cellRenderer: 'CopyTextCell',
            headerTooltip: 'IP Extern',
            width: 120,
        },
        {
            headerName: 'IP Intern',
            colId: 'ipInternal',
            field: 'bot.ipInternal',
            cellRenderer: 'CopyTextCell',
            headerTooltip: 'IP Intern',
            width: 120,
        },
        {
            headerName: 'Monitor',
            colId: 'monitor',
            field: 'bot.monitor',
            cellRenderer: 'BooleanIconCell',
            headerTooltip: 'Monitor',
            width: 50,
        },
        {
            headerName: 'Active',
            colId: 'active',
            field: 'bot.active',
            cellRenderer: 'BooleanIconCell',
            headerTooltip: 'Aktiv',
            width: 50,
        },
        { headerName: '#User', colId: 'user', field: 'meta.anzahlBenutzer', headerTooltip: 'Anzahl User', width: 50 },
        {
            headerName: '#Patienten',
            colId: 'patienten',
            field: 'meta.anzahlPatienten',
            headerTooltip: 'Anzahl Patienten',
            width: 50,
        },
        {
            headerName: '#Termine',
            colId: 'termine',
            field: 'meta.anzahlTermine',
            headerTooltip: 'Anzahl Termine',
            width: 50,
        },
        {
            headerName: '#P (r4c)',
            colId: 'r4cPatienten',
            field: 'metar4c.anzahlPatienten',
            headerTooltip: 'r4c Anzahl Patienten',
            width: 70,
        },
        {
            headerName: '#T (r4c)',
            colId: 'r4cTermine',
            field: 'metar4c.anzahlTermine',
            headerTooltip: 'r4c Anzahl Termine',
            width: 70,
        },
        {
            headerName: 'r4cHost',
            colId: 'r4cHost',
            field: 'r4chost',
            valueFormatter: common_ui_1.hostnameFormatter,
            headerTooltip: 'r4c Host',
            width: 70,
        },
        {
            headerName: 'Letzte ewe',
            colId: 'r4cLastEwe',
            field: 'r4cStats.lastewe',
            valueFormatter: common_ui_1.dateFormatter,
            headerTooltip: 'Letzte EWE',
            width: 100,
        },
        {
            headerName: '#ewe',
            colId: 'r4cEweCount',
            field: 'r4cStats.ewecount',
            headerTooltip: 'r4c Anzahl EWE',
            width: 60,
        },
        {
            headerName: '#clicks',
            colId: 'r4cClicks',
            field: 'r4cStats.clicks',
            headerTooltip: 'r4c Anzahl Clicks',
            width: 60,
        },
        {
            headerName: '#docs',
            colId: 'r4cSignedDocuments',
            field: 'r4cStats.signeddocumentcount',
            headerTooltip: '#docs',
            width: 60,
        },
    ];
}
exports.clientsColumns = clientsColumns;
