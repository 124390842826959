"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const base_1 = require("../../../../base");
exports.default = (0, vue_1.defineComponent)({
    name: 'AboStatusCell',
    data: () => ({
        params: {},
    }),
    computed: {
        aboStatus() {
            return this.params.value;
        },
        reason() {
            var _a, _b, _c, _d;
            if (!this.params.value && ((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.rosefrom) && !((_b = this.params.data) === null || _b === void 0 ? void 0 : _b.stripefrom)) {
                return 'No Stripe';
            }
            if (!this.params.value && (0, base_1.roseDayjs)((_c = this.params.data) === null || _c === void 0 ? void 0 : _c.roseto).isAfter((0, base_1.roseDayjs)((_d = this.params.data) === null || _d === void 0 ? void 0 : _d.stripeto))) {
                return 'Stripe Expired';
            }
            return '';
        },
    },
});
