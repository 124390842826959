"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFilter = void 0;
/* Subscription Grid Filters */
var DateFilter;
(function (DateFilter) {
    DateFilter["NONE"] = "none";
    DateFilter["START"] = "start";
    DateFilter["END"] = "end";
    DateFilter["RUNNING"] = "running";
})(DateFilter || (exports.DateFilter = DateFilter = {}));
